import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ca bootstrap`}</strong>{` -- initialize the environment to use the CA commands`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ca bootstrap
[--ca-url=<uri>] [--fingerprint=<fingerprint>] [--install]
[--team=<name>] [--authority=<name>] [--team-url=<uri>] [--redirect-url=<uri>]
[--context=<name>] [--profile=<name>]
[--authority=<name>] [--team-authority=<sub-domain>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca bootstrap`}</strong>{` downloads the root certificate from the certificate
authority and sets up the current environment to use it.`}</p>
    <p>{`Bootstrap will store the root certificate in `}<inlineCode parentName="p">{`$STEPPATH/certs/root_ca.crt`}</inlineCode>{` and
create a configuration file in `}<inlineCode parentName="p">{`$STEPPATH/configs/defaults.json`}</inlineCode>{` with the CA
url, the root certificate location and its fingerprint.`}</p>
    <p>{`After the bootstrap, ca commands do not need to specify the flags
--ca-url, --root or --fingerprint if we want to use the same environment.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--fingerprint`}</strong>{`=`}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{`
The `}<inlineCode parentName="p">{`fingerprint`}</inlineCode>{` of the targeted root certificate.`}</p>
    <p><strong parentName="p">{`--install`}</strong>{`
Install the root certificate into the system truststore.`}</p>
    <p><strong parentName="p">{`--team`}</strong>{`=`}<inlineCode parentName="p">{`ID`}</inlineCode>{`
The team `}<inlineCode parentName="p">{`ID`}</inlineCode>{` used to bootstrap the environment.`}</p>
    <p><strong parentName="p">{`--team-authority`}</strong>{`=`}<inlineCode parentName="p">{`sub-domain`}</inlineCode>{`
The `}<inlineCode parentName="p">{`sub-domain`}</inlineCode>{` of the certificate authority to bootstrap. E.g., for an authority with
domain name 'certs.example-team.ca.smallstep.com' the value would be 'certs'.`}</p>
    <p><strong parentName="p">{`--team-url`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The `}<inlineCode parentName="p">{`url`}</inlineCode>{` step queries to retrieve initial team configuration. Only used with
the `}<strong parentName="p">{`--team`}</strong>{` option. If the url contains `}<inlineCode parentName="p">{`<>`}</inlineCode>{` placeholders, they are replaced with the team ID.
Replacing the authority-id section of the url is not supported with placeholders.`}</p>
    <p><strong parentName="p">{`--redirect-url`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
The `}<inlineCode parentName="p">{`url`}</inlineCode>{` to open in the system browser when the OAuth flow is successful.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <p><strong parentName="p">{`--profile`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the profile name for the context.`}</p>
    <p><strong parentName="p">{`--authority`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The `}<inlineCode parentName="p">{`name`}</inlineCode>{` that will serve as the authority name for the context.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Bootstrap using the CA url and a fingerprint:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca bootstrap --ca-url https://ca.example.org \\
  --fingerprint d9d0978692f1c7cc791f5c343ce98771900721405e834cd27b9502cc719f5097
`}</code></pre>
    <p>{`Bootstrap and install the root certificate`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca bootstrap --ca-url https://ca.example.org \\
  --fingerprint d9d0978692f1c7cc791f5c343ce98771900721405e834cd27b9502cc719f5097 \\
  --install
`}</code></pre>
    <p>{`Bootstrap with a smallstep.com CA using a team ID:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca bootstrap --team superteam
`}</code></pre>
    <p>{`To use team IDs in your own environment, you'll need an HTTP(S) server
serving a JSON file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`{"url":"https://ca.example.org","fingerprint":"d9d0978692f1c7cc791f5c343ce98771900721405e834cd27b9502cc719f5097"}
`}</code></pre>
    <p>{`Then, this command will look for the file at `}<a parentName="p" {...{
        "href": "https://config.example.org/superteam"
      }}>{`https://config.example.org/superteam`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ca bootstrap --team superteam --team-url https://config.example.org/<>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      